import * as React from "react"
import { Link, graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"

import "../styles/main.scss"
import Layout from "../layouts/layout"
import { GatsbyImage } from "gatsby-plugin-image"
import { Helmet } from "react-helmet"

export default class NotePost extends React.Component {

    props: {
        data:  {
            mdx: {
                body: string;
                frontmatter?: any
            }
        }
    }
    components: any;

    constructor(props) {
        super(props)

        this.components = {

        };
    }

    public getCoverImage() {
        if (this.props.data.mdx.frontmatter.cover) {
            return (<GatsbyImage image={this.props.data.mdx.frontmatter.cover.childImageSharp.gatsbyImageData} alt="Cover Image"/>)
        } else {
            return (<div></div>)
        }
    }

    public render() {

        return (
            <Layout>
                <Helmet>
                    <title>{this.props.data.mdx.frontmatter.title}</title>
                </Helmet>
                <header className={"container right-split-layout"}>
                    <div>
                        <time>{this.props.data.mdx.frontmatter.date}</time>
                        <h1 className={"header-title"}>{this.props.data.mdx.frontmatter.title}</h1>
                    </div>
                    {this.getCoverImage()}
                </header>
                <article className={"container"}>
                    <MDXRenderer>{this.props.data.mdx.body}</MDXRenderer>
                </article>
            </Layout>
        )
    }
}

export const pageQuery = graphql`
    query NoteQuery ($slug: String!) {
        mdx ( slug: { eq: $slug } ) {
            body
            slug
            frontmatter {
                title
                date (formatString: "DD MMMM YYYY")
                cover {
                    childImageSharp {
                        gatsbyImageData
                    }
                }
            }
        }
    }
`